up.compiler('#reset-filter', (element) => {
    let form = element.form;

    element.addEventListener('click', function(event){
        event.preventDefault();
        form.reset();

        // empty select boxes
        let selectpickerList = form.querySelectorAll('select.selectpicker')
        selectpickerList.forEach((selectpicker) => {
            var defvalue = ''
            if (selectpicker.hasAttribute('filter-default')) {
                try {
                    defvalue = JSON.parse(selectpicker.getAttribute('filter-default'));
                }
                catch {
                    defvalue = selectpicker.getAttribute('filter-default');
                }
            }
            $(selectpicker).val(defvalue);
            $(selectpicker).selectpicker('refresh');
        })

        // reset checkboxes
        let checkboxList = form.querySelectorAll('input[type="checkbox"]')
        checkboxList.forEach((checkbox) => {
            var defvalue = false
            if (checkbox.hasAttribute('filter-default')) {
                try {
                    defvalue = JSON.parse(checkbox.getAttribute('filter-default'));
                }
                catch {
                    defvalue = checkbox.getAttribute('filter-default');
                }
            }
            checkbox.checked = defvalue;
        })

        // reset daterange slider
        let daterangeList = form.querySelectorAll('input.daterange-picker')
        daterangeList.forEach((daterange) => {
            var defvalue = ''
            if (daterange.hasAttribute('filter-default')) {
                defvalue = daterange.getAttribute('filter-default');
            }
            $(daterange).val(defvalue);
        })

        form.querySelectorAll('.query-searchbox')[0].value = '';

        // set delete & hide delete button
        let delete_input = form.querySelector(element.getAttribute('target'))
        delete_input.checked = true
        delete_input.dispatchEvent(new Event('change'))
        element.classList.add('d-none');
        up.submit(form);
    })

    form.addEventListener('change', function(){
        element.classList.remove('d-none');
    })
})

up.compiler('#toggle-filter', (element) => {
    element.addEventListener('click', function(event){
        event.preventDefault();
    })
})

up.$compiler('.filter--layer input.daterange-picker', $element => {
    $element.on('change', function(event, picker) {
        if(event.originalEvent == undefined){
            $element[0].dispatchEvent(new Event('change'));
            $element[0].form.dispatchEvent(new Event('change'));
        }
    });
})

up.compiler('[class*="update-on-filter"]', element => {
    if(element.querySelector('input') != undefined){
        up.autosubmit(element);
    }
})