up.compiler('table input#select_all', (element) => {
  const body = element.closest('table').querySelector('tbody')
  const checkboxes = body.querySelectorAll("tr > td:first-child input[type='checkbox']");
  element.addEventListener('change', () => {
    if(element.checked){
      checkboxes.forEach(checkbox => {

        checkbox.checked = true
      })
    } else {
      checkboxes.forEach(checkbox => {
        checkbox.checked = false
      })
    }
  });
});
