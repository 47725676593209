up.compiler('.print-pdf', (element) => {
  element.addEventListener('click', () => {
    var pdfObject = document.getElementById(element.getAttribute('data-pdf-object-id'));
    if (pdfObject) {
      var iframe = document.createElement('iframe');
      iframe.style.position = 'absolute';
      // iframe.style.width = '0px';
      // iframe.style.height = '0px';
      iframe.src = pdfObject.data;
      document.body.appendChild(iframe);
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }
  });
});
