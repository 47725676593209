window.getEnvironment = function() {
    return document.body.getAttribute('data-env')
}

window.inTestEnvironment = function() {
    return getEnvironment() === 'test'
}

window.inDevEnvironment = function() {
    return getEnvironment() === 'development'
}
