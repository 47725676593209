up.compiler('.copy-clipboard', ( element ) => {
  element.addEventListener('click', () => {
    const text = element.getAttribute('clipboard-text');
    const target = element.getAttribute('clipboard-target');

    let selected = ''
    if(text != undefined){
      selected = text;
    } else if (target != undefined){
      const targets = document.querySelectorAll(target);
      if(targets != undefined){
        const firstTarget = targets[0]
        if(firstTarget.tag == 'input'){
          firstTarget.select();
          firstTarget.setSelectionRange(0, 99999);
          selected = firstTarget.value;
        } else {
          selected = firstTarget.innerText;
        }
      }
    }

    navigator.clipboard.writeText(selected)

    const content = element.innerHTML;
    element.innerHTML = 'copied!'
    setTimeout(()=>{
      element.innerHTML = content;
    }, 1500);
  })
});


