up.compiler('.chartkick-chart', (element) =>{
    // Turbolinks preview restores the DOM except for painted <canvas>
    // since it uses cloneNode(true) - https://developer.mozilla.org/en-US/docs/Web/API/Node/
    //
    // don't rerun JS on preview to prevent
    // 1. animation
    // 2. loading data from URL
    if (document.documentElement.hasAttribute("data-turbolinks-preview")) return;

    type = element.getAttribute('chartkick-type');
    id = element.getAttribute('chartkick-id');

    data = element.getAttribute('chartkick-data');
    try { data = JSON.parse(data);}
    catch(err){  }

    options = element.getAttribute('chartkick-options');
    try { options = JSON.parse(options);}
    catch(err){ }

    if(type == 'PieChart' && options['donut']) {
        var metaInfoLink = data + '?meta_info=true'
        options['library']['animation']['onComplete'] = function(chartInstance){
            doughnut_center_label(chartInstance, metaInfoLink)
        };
    }

    var createChart = function() { new Chartkick[type](id, data, options); };

    if ("Chartkick" in window) {
        createChart();
    } else {
        window.addEventListener("chartkick:load", createChart, true);
    }
});


function doughnut_center_label(chartInstance, metaInfoLink){
    if(chartInstance.centerPlaced == undefined) {
        var canvas = chartInstance.chart.canvas;
        var dataLabel = create_doughnut_center_label(canvas.parentElement);
        getJSON(metaInfoLink, function(err, data){
            if(err == null){
                dataLabel.innerHTML = data['center_label'] || '';
                chartInstance.centerPlaced = true;
            }
        });
    }
}

function create_doughnut_center_label(element){
    var chartLables = element.getElementsByClassName('dountchart-center-label');
    if(chartLables.length == 0) {
        var span = document.createElement("span");
        span.classList.add('dountchart-center-label');
        element.appendChild(span);
        return span;
    } else {
        return chartLables[0];
    }
}
