up.compiler('#delete-service-new-item', (element) => {
  var service_id = element.getAttribute('service-id');
  var form = element.closest('form');
  var options = {
    'up-history': element.getAttribute('up-history'),
    'up-target': element.getAttribute('up-target'),
    headers: {
      'X-Up-Validate': 'outbound_invoice_item[delete_service_id]'
    }
  };

  element.addEventListener('click', (event) => {
    event.preventDefault();

    var box = form.querySelector('#outbound_invoice_item_service_type_ids');
    var option = box.querySelector('[value="' + service_id + '"]');
    option.selected = false;
    up.submit(form, options);
  });
});
