function isOverflown(element) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

up.compiler('.index-card .show-more', (element) => {
    if(!isOverflown(element.parentElement.firstElementChild)){
        element.classList.add('d-none');
    }
})

up.compiler('.show-more.-if-necessary', (element) => {
    if(!isOverflown(element.parentElement.firstElementChild)){
        element.classList.add('d-none');
    }
})
