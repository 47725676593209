up.compiler('.btn-input-clear', (element) => {
  const inpt = element.previousElementSibling;
  if(inpt.tagName.toLowerCase() == 'input'){
    element.addEventListener('click', (event) => {
      event.preventDefault();
      inpt.value= '';
      const changeEvent = new Event("change", { bubbles: true });
      inpt.dispatchEvent(changeEvent);
    })
  }
});
