// delay these unpoly validations to allow other event handlers do their thing before validation change fragments

up.$compiler('.datetime_picker', ($element) => {
    const now = moment();
    $element.datetimepicker({
        locale: 'de'
    });
    $element.on('change.datetimepicker', function(e){
        if(e.oldDate != null) {
            let input = $element.find('input');
            setTimeout(() => {
                up.validate(input, {target: determineUpTarget($element)});
            }, 1);
        }
    });
})

up.$compiler('.date_picker', ($element) => {
    const now = moment();
    $element.datetimepicker({
        locale: 'de',
        format: 'L'
    });
    $element.on('change.datetimepicker', function(e){
        if(e.oldDate != null) {
            let input = $element.find('input');
            if(input.attr('timeless') != 'true') {
                setTimeout(() => {
                    up.validate(input, {target: determineUpTarget($element)});
                }, 1);
            }
        }
    });
})

up.$compiler('.month_picker', ($element) => {
    const now = moment();

    let allowed_month = $element[0].getAttribute('allowed-month');
    if(allowed_month != undefined) {
        allowed_month = JSON.parse(allowed_month);
        allowed_month = allowed_month.map(dateString => {
            const [month, day, year] = dateString.split('/');
            // Months are 0 indexed in JavaScript Date object, so we subtract 1
            return new Date(year, month - 1, day);
        });
    }

    $element.datetimepicker({
        locale: 'de',
        format: 'MMMM YYYY',
        viewMode: 'months',
        enabledDates: allowed_month
    });
    $element.on('change.datetimepicker', function(e){
        if(e.oldDate != null) {
            let input = $element.find('input');
            if(input.attr('timeless') != 'true') {
                setTimeout(() => {
                    up.validate(input, {target: determineUpTarget($element)});
                }, 1);
            }
        }
    });
})

up.$compiler('.time_picker', ($element) => {
    $element.datetimepicker({
        locale: 'de',
        format: 'LT'
    });
    $element.on('change.datetimepicker', function(e){
        if(e.oldDate != null) {
            let input = $element.find('input');
            setTimeout(() => {
                up.validate(input, {target: determineUpTarget($element)});
            }, 1);
        }
    });
})

up.$compiler('.clockpicker', ($element) => {
    $element.clockpicker({
        placement: 'top',
        autoclose: true,
        twelvehour: false,
        default: 'now',
        donetext: 'OK',
        afterDone: triggerChange
    });

    function triggerChange(){
        let input = $element.find('input')[0];
        setTimeout(() => {  up.validate(input, {target: determineUpTarget($element)}); }, 1);
     }

})

function determineUpTarget($element){
    if ($element instanceof jQuery) {
        element = $element[0];
      } else {
        element = $element;
      }

    let target = element.getAttribute('up-validate');
    if(target == null) {
        target = element.querySelector('input').getAttribute('up-target');
    }
    if(target == null) {
        target = element.closest('form').getAttribute('up-target');
    }
    return target;
}