up.compiler('.nav-shrink', (element) => {
    var title = element.querySelector('.app-name');
    window.addEventListener('scroll', function(){
        if(window.scrollY >= 30){
            element.classList.add('shrink');
            title.classList.add('d-none');
        } else{
            element.classList.remove('shrink');
            title.classList.remove('d-none')
        }
    })
})