up.compiler('[up-replace]', (element) =>{
    var target = element.getAttribute('up-replace');
    element.addEventListener("click", function(){
        up.replace(target, window.location.pathname);
    });
});

up.compiler('.modal--close', (element) =>{
    if(element.closest('.up-modal-dialog') != undefined){
        element.setAttribute('up-close', '')
        element.setAttribute('up-history', document.referrer)
    }
});

up.compiler('input[up-history]', (element) =>{
    var url = element.getAttribute('up-history')
    element.onclick = function(){up.history.push(url)}
});

up.compiler('a[up-request]', (element) =>{
    if(element.getAttribute('up-request') == 'true') {
        up.follow(element)
    }
});

up.compiler('[up-switch-click]', (element) => {
    var toggles = document.querySelectorAll(element.getAttribute('up-switch-click'));
    register_next_btn(element, toggles);
});

up.compiler('[up-modal-close-after]', (element) => {
    element.addEventListener('click', function(){
        var modal = element.closest('.up-modal-dialog')
        if(modal != undefined){
            setTimeout(function(){
                up.modal.close(modal)
            }, element.getAttribute('up-modal-close-after')*1000)
        }
    })
});


function click_toggle(toggles){
    toggles.forEach((toggle) => {
        if(toggle.classList.contains('d-none')){
            toggle.classList.remove('d-none');
        } else {
            toggle.classList.add('d-none');
        }
    })
}

function register_next_btn(element, toggles){
    if(element.hasAttribute('up-switch-click-btn')){
        var nextBtn = document.querySelector(element.getAttribute('up-switch-click-btn'))

        if(!element.classList.contains('up-switch-click-registered')){
            element.addEventListener('click', (event) => {
                click_toggle(toggles);
                element.classList.add('d-none');
                nextBtn.classList.remove('d-none');
            })

            element.classList.add('up-switch-click-registered')
            register_next_btn(nextBtn, toggles);
        }
    }
}
