var spinnerCntr = 0;
up.compiler('[up-spinner]:not(.registred)', (element) =>{
    if(element.classList.contains("spinner-"+spinnerCntr)){
        return;
    }

    var spinnerHTML = '<span class="default-spinner"></span>'
    var spinnerNode = document.createElement('div');

    var classes = element.getAttribute('up-spinner');
    if(classes == ''){
        classes = 'spinner-wrapper';
    }

    var spinnerTarget = element;
    if (element.hasAttribute('up-spinner-target')){
        spinnerTarget = document.querySelector(element.getAttribute('up-spinner-target'));
        classes += " spinner-"+spinnerCntr;
        if(element.hasAttribute('up-target')){
            if((targetElement = spinnerTarget.querySelector(element.getAttribute('up-target'))) != undefined){
                // this is the moment, where the actual up-target is removed an does not work anymore..
                // we try to extract the selector id and apply it to the spinner
                targetElementID = targetElement.getAttribute('id');
                if (targetElementID == undefined){
                    console.warn('Zetas spinner will replace a unpoly up-target node, but could find an id-selector to reference the spinner itself instead. Please use an id (#) within the corresponding up-target')
                }
                spinnerNode.setAttribute('id', targetElementID);
            }
        }
        element.classList.add('registred');
        spinnerCntr += 1;
    }
    else if (element.hasAttribute('up-target')){
        spinnerTarget = document.querySelector(element.getAttribute('up-target'));
    }

    spinnerNode.setAttribute('class', classes);
    spinnerNode.innerHTML = spinnerHTML.trim();

    function showSpinner(e, keep_target=false) {
        if(keep_target){
            spinnerTarget.parentElement.insertBefore(spinnerNode, spinnerTarget);
            spinnerTarget.classList.add('d-none')
        } else {
            setTimeout(function(){
                let trgt = spinnerTarget.parentElement;
                if(trgt == null){
                    return;
                } else {
                    trgt.replaceChild(spinnerNode, spinnerTarget);
                }
            }, 500);
        }
    }
    function showSpinnerAfterConfirmation(e){
        if(e.detail[0]){
            showSpinner(null, keep_target=true);
        }
    }

    var form;
    if(element.tagName.toLowerCase() == 'form'){
        form = element;
    }
    else if(element.tagName.toLowerCase() == 'input') {
        form = element.form;
    }

    object_to_load = element.getAttribute('show-until-loaded')
    if(object_to_load != undefined)
    {
        object_to_load = document.querySelector(object_to_load)
        object_to_load.onload = function() { 
            element.parentElement.remove();
        }
        showSpinner(null, keep_target=true);
    }

    if(form != undefined) {
        if(form.hasAttribute('data-confirm')){
            form.addEventListener('confirm:complete', showSpinnerAfterConfirmation)
        } else if((submitBtn = form.querySelector('button[type="submit"]')) != undefined && submitBtn.hasAttribute('data-confirm')) {
            submitBtn.addEventListener('confirm:complete', showSpinnerAfterConfirmation);
        }
        else {
            element.addEventListener('submit', showSpinner)
        }
    } else {
        element.addEventListener('click', showSpinner)
    }
});
