up.compiler('.custom-mail.preview-button', element => {
   element.addEventListener('click', showPreview);
   let button_html = element.innerHTML;
   function showPreview(evt) {
       evt.preventDefault();

       let preview = element.parentElement.parentElement.querySelector('.mail-body');
       let formGroup = element.parentElement.parentElement.querySelector('.form-group.custom_mail_body')
       if(element.classList.contains('back-to-editor')){
           element.innerHTML = button_html;
           element.classList.remove('back-to-editor');
           preview.setAttribute('hidden', 'hidden');
           formGroup.removeAttribute('hidden');
       } else {
           let formData = new FormData(formGroup.querySelector('textarea').form);
           let prom = up.replace('.mail-body', element.querySelector('a').getAttribute('href'), {method: 'post', params: formData, cache: false});
           prom.then(function() {
               element.innerHTML = "<a href=\"#\"><i class=\"icon-markdown fas fa-reply mr-2\" aria-label=\"markdown\"></i><span class=\"\">Editor</span></a>"
               element.classList.add('back-to-editor');
               formGroup.setAttribute('hidden', 'hidden');
               preview.removeAttribute('hidden');
           });
       }
   }
});
