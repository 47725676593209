up.$compiler('[data-toggle="tooltip"]', ($element) => {
    $element.tooltip();
})

// remove tooltips by up-target links with tooltips
up.compiler('[up-target]', (element) => {

    element.addEventListener('click', function(){
        let tooltip = this.getAttribute('aria-describedby');
        if (tooltip) {
            document.getElementById(tooltip).remove();
        }
    });
})