up.compiler('#joblog_start_at_date', (element) => {
    let target = document.getElementById('joblog_stop_at_date');

    var copy = function(){
       if(target && target.value == ''){
           target.value = this.value;
       }
    };

    element.addEventListener('blur', copy);
    element.addEventListener('change.datetimepicker', copy);
});

up.compiler('.toggle-ae', (element) => {
    let body = element.closest('table').querySelector('tbody');
    toggle_ae(element, body, initial=true);
    element.addEventListener('click', function(){
        toggle_ae(element, body);
    })
});

function toggle_ae(button, body, initial=false){
    let cookie = 'zetas[joblogs_index]-duration_unit';
    let on = [];
    let off = []; body.querySelectorAll('.duration_unit.ae');

    var duration_unit = getCookie(cookie);
    if((initial && duration_unit == 'ae') || (duration_unit == 'humanized' && !initial)){
        on = body.querySelectorAll('.duration_unit.humanized');
        off = body.querySelectorAll('.duration_unit.ae');
        button.classList.remove('text-muted');
        button.classList.add('text-secondary');
        duration_unit = 'ae'
    } else if((initial && duration_unit == 'humanized') || (duration_unit == 'ae' && !initial)){
        on = body.querySelectorAll('.duration_unit.ae');
        off = body.querySelectorAll('.duration_unit.humanized');
        button.classList.remove('text-secondary');
        button.classList.add('text-muted');
        duration_unit = 'humanized'
    } else {
        duration_unit = 'humanized'
    }

    off.forEach((node) => {
        node.classList.remove('d-none');
    });
    on.forEach((node) => {
        node.classList.add('d-none');
    });

    setCookie(cookie, duration_unit, 30);
}


function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Lax";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
