up.$compiler('.toggle_switch', ($element) => {
    $element.bootstrapToggle();
});

up.compiler('.toggle-off', (element) => {
    element.addEventListener('click', function() {
        let input = element.parentElement.parentElement.querySelector('.toggle_switch');
        input.value = 1;
        if(input.getAttribute('up-validate') != null){
            setTimeout(() => {
                up.validate(input, { target: input.getAttribute('up-validate') })
            }, 500);
        }
    });
});


up.compiler('.toggle-on', (element) => {
    element.addEventListener('click', function() {
        let input = element.parentElement.parentElement.querySelector('.toggle_switch');
        input.value = 0;
        if(input.getAttribute('up-validate') != null){
            setTimeout(() => {
                up.validate(input, { target: input.getAttribute('up-validate') })
            }, 500);
        }
    });
});
