up.compiler('.stop-propagation', (element) =>{
    element.addEventListener('click', function(e){
           e.stopPropagation();
    })
    element.addEventListener('input', function(e){
        e.stopPropagation();
    })
    element.addEventListener('change', function(e){
        e.stopPropagation();
    })
})