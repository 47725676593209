// date range picker configuration
// https://www.daterangepicker.com/#usage

up.$compiler('input.daterange-picker', $element => {
    let server_value = $element.val(); // we need to save the server input value, because daterangepicker set default value on auto update mode

    $element.daterangepicker({
        minYear: 2000,
        maxYear: 2100,
        showWeekNumbers: true,
        ranges: {
            'Letzen 7 Tage': [moment().subtract(6, 'days'), moment()],
            'Letzten 30 Tage': [moment().subtract(29, 'days'), moment()],
            'Letzten 365 Tage': [moment().subtract(365, 'days'), moment()],
            'Dieser Monat': [moment().startOf('month'), moment().endOf('month')],
            'Letzter Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Dieses Jahr': [moment().startOf('year'), moment().endOf('year')],
            'Letztes Jahr': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
        },
        locale: {
            format: 'DD.MM.YYYY',
            applyLabel: "Anwenden",
            cancelLabel: "Löschen",
            customRangeLabel: "selbst gewählt",
            daysOfWeek: [
                "So",
                "Mo",
                "Di",
                "Mi",
                "Do",
                "Fr",
                "Sa"
            ],
            monthNames: [
                "Januar",
                "Februar",
                "März",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Dezember"
            ],
            firstDay: 1,
        },
        linkedCalendars: false,
        alwaysShowCalendars: true,
        parentEl: "body",
        opens: "center",
        cancelClass: "btn-danger",
    });

    $element.on('cancel.daterangepicker', function(ev, picker) {
        $element.val('');
        $element.change();
    });

    $element.val(server_value); // now we need to set the server input value, because daterangepicker has set a default value
})

up.compiler('input.daterange-picker + .input-group-append', element => {
    element.addEventListener('click', function(){
       element.previousElementSibling.click();
    });
})

up.compiler('input.daterange-picker + .input-group-prepend', element => {
    element.addEventListener('click', function(){
        element.nextElementSibling.click();
    });
})