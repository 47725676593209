const { DomPlatform } = require("chart.js");

up.compiler('.btn-group-toggle.up-toggle', (element) => {
    var group_elements = element.querySelectorAll('a, button, input, label');

    var activate_first = true;
    group_elements.forEach((button) => {
        if (button.classList.contains('active')) {
            activate_first = false
        }
        button.addEventListener('click', function(){
            group_elements.forEach((other_button) => {
                deactivate(other_button);
            })
            activate(button);
        })
    })

    if (activate_first) {
        activate(group_elements[0]);
    }
})

up.compiler('[toggle-up-switch]', (element) => {
    let targets = document.querySelectorAll(element.getAttribute('toggle-up-switch'))
    targets.forEach((target) => {
        upShowAndHide(element, target);
    });
})


function deactivate(element){
    if (element.classList.contains('active')) {
        element.classList.remove('active');
        element.setAttribute('aria-pressed', 'false')
        
        var inputElement = undefined;
        if(element.tagName.toLowerCase() === 'input') {
            inputElement = element
        } else if(element.firstElementChild.tagName.toLowerCase() === 'input'){
            inputElement = element.firstElementChild;
        }

        if(inputElement != undefined) {
            inputElement.removeAttribute('checked');
            if(inputElement.hasAttribute("toggle-up-switch")){
                let targets = document.querySelectorAll(inputElement.getAttribute('toggle-up-switch'))
                targets.forEach((target) => {
                    upShowAndHide(inputElement, target);
                });
            }
        }
    }
}

function activate(element){
    element.classList.add('active');
    element.setAttribute('aria-pressed', 'true')

    var inputElement = undefined;
    if(element.tagName.toLowerCase() === 'input') {
        inputElement = element
    } else if(element.firstElementChild.tagName.toLowerCase() === 'input'){
        inputElement = element.firstElementChild;
    }


    if(inputElement != undefined) {
        inputElement.setAttribute('checked', 'checked');
        if(inputElement.hasAttribute("toggle-up-switch")){
            let targets = document.querySelectorAll(inputElement.getAttribute('toggle-up-switch'))
            targets.forEach((target) => {
                upShowAndHide(inputElement, target);
            });
        }
    }
}

function upShowAndHide(input, target){
    
    if(target.getAttribute('toggle-up-show-for') == ':checked' && input.checked){
        target.classList.remove('d-none');
    } else if(target.getAttribute('toggle-up-show-for') == ':checked' && !input.checked){
        target.classList.add('d-none');
    } else if(target.getAttribute('toggle-up-show-for') == ':unchecked' && !input.checked) {
        target.classList.remove('d-none');
    } else if(target.getAttribute('toggle-up-show-for') == ':unchecked' && input.checked) {
        target.classList.add('d-none');
    } else if(target.getAttribute('toggle-up-hide-for') == ':checked' && input.checked) {
        target.classList.add('d-none');
    } else if(target.getAttribute('toggle-up-hide-for') == ':checked' && !input.checked) {
        target.classList.remove('d-none');
    } else if(target.getAttribute('toggle-up-hide-for') == ':unchecked' && !input.checked) {
        target.classList.add('d-none');
    } else if(target.getAttribute('toggle-up-hide-for') == ':unchecked' && input.checked) {
        target.classList.remove('d-none');
    }
}
