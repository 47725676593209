// from http://sortablejs.github.io/Sortable/

import {Sortable, MultiDrag } from 'sortablejs'
up.compiler('.sortable', (element) => {
  var options = {
    animation: element.getAttribute('sort-animation') || 150,
    ghostClass: element.getAttribute('sort-ghost-class') || 'ghost'
  }

  if(element.hasAttribute('sort-group')) {
    options.group = element.getAttribute('sort-group')
  }

  if(element.hasAttribute('sort-allowed')) {
    options.sort = element.getAttribute('sort-allowed')
  }

  if(element.hasAttribute('sort-handle')) {
    if(element.getAttribute('sort-handle') == ''){
      options.handle = '.sort-handle'
    } else {
      options.handle = element.getAttribute('sort-handle')
    }
  }

  if(element.hasAttribute('sort-onEnd')) {
    if (element.getAttribute('sort-onEnd') == 'onSortEditOutboundInvoiceItems') {
      options.onEnd = onSortEditOutboundInvoiceItems;
    }
  }

  /* does not work right now... */
  if(element.classList.contains('sort-multidrag')) {
    options.multiDrag = true,
    options.selectedClass = 'sortable-selected'
    options.multiDragKey = null
    options.fallbackTolerance = 3
    options.handle = null
  }

  new Sortable(element, options)
});


// Outbound Invoice 

function onSortEditOutboundInvoiceItems(event){
  var table = document.getElementById('invoice-table');
  var list = event.from;
  var url = list.getAttribute('sort-onEnd-Url');
  var upTarget = list.getAttribute('up-target');

  var groupCounter = 1;
  var positions = [];
  table.querySelectorAll('tbody').forEach( (group) => {
    if(!group.classList.contains('skip-sort')) {

      var groupid = group.getAttribute('group-id');

      var itemCounter = 1;
      var itemIds = [];
      var rowElements = group.querySelectorAll('td[item-id]');
      rowElements.forEach(function(td) {
        var itemId = td.getAttribute('item-id');
        itemIds.push({item_id: itemId, position: itemCounter});
        itemCounter = itemCounter + 1;
      });

      positions.push({group_id: groupid, position: groupCounter, items: itemIds});
      groupCounter = groupCounter + 1;
    }
  });

  up.replace(upTarget, url, {method: 'post', history: false, params: {positions: JSON.stringify(positions)}});
}
