up.compiler('.form-group.radio_buttons', (element) => {
    let buttons = element.querySelectorAll('input');
    buttons.forEach( (button) => {
        button.addEventListener('input', toggleRadios);
        if(button.checked){
            setActive(button.parentElement)
        }
    })
})


function toggleRadios(event){
    let clicked = event.target.parentElement;
    setActive(clicked);
    for (let sibling of clicked.parentElement.parentElement.children) {
        if (sibling.tagName !== 'INPUT' && sibling.querySelector('label') !== clicked) setIncative(sibling.querySelector('label'));
    }

    let input = clicked.querySelector('input[type="radio"]');
    clicked.closest('.input-group').querySelector('input[type="hidden"]').setAttribute('value', input.value);
}

function setActive(label){
    label.classList.add('active', 'text-white');
    label.classList.remove('text-primary');
}

function setIncative(label){
    label.classList.remove('active', 'text-white');
    label.classList.add('text-primary');
}
